<template>
    <!-- Page: pages/browse-procurements -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <h1>Browse Bids</h1>
        <userTools/>
    </div>

    <hr/>

    <div class="container procurements-container procurements-container--stats">
        <div class="columns" v-if="!procurementInfoLoading">
            <div class="column is-half-mobile" @click="() => { selectStatus('Open'); }">
                <div>
                    <div class="procurement-count">
                        {{ procurementInfo.openCount }}
                    </div>
                    <div class="procurement-count-title">
                        <span class="procurement-type-icon">
                            <font-awesome-icon :icon="faFolderOpen" aria-label="Preview procurements icon"></font-awesome-icon>
                        </span>
                        Open
                    </div>
                </div>
            </div>
            <div class="column is-half-mobile" @click="() => { selectStatus('Withdrawn'); }">
                <div>
                    <div class="procurement-count">
                        {{ procurementInfo.withdrawnCount }}
                    </div>
                    <div class="procurement-count-title">
                        <span class="procurement-type-icon">
                            <font-awesome-icon :icon="faFolderArrowDown" aria-label="Preview procurements icon"></font-awesome-icon>
                        </span>
                        Withdrawn
                    </div>
                </div>
            </div>
            <div class="column is-half-mobile" @click="() => { selectStatus('Closed'); }">
                <div>
                    <div class="procurement-count">
                        {{ procurementInfo.closedCount }}
                    </div>
                    <div class="procurement-count-title">
                        <span class="procurement-type-icon">
                            <font-awesome-icon :icon="faFolder" aria-label="Preview procurements icon"></font-awesome-icon>
                        </span>
                        Closed
                    </div>
                </div>
            </div>
            <div class="column is-half-mobile" @click="() => { selectStatus('Preview'); }">
                <div>
                    <div class="procurement-count">
                        {{ procurementInfo.previewCount }}
                    </div>
                    <div class="procurement-count-title">
                        <span class="procurement-type-icon">
                            <font-awesome-icon :icon="faBinoculars" aria-label="Preview procurements icon"></font-awesome-icon>
                        </span>
                        Preview
                    </div>
                </div>
            </div>
        </div>
        <div class="procurements-loading" v-if="procurementInfoLoading">
            <Preloader />
        </div>
    </div>

    <procurement-pie-chart-container
        :procurement-info="procurementInfo"
        :procurement-info-loading="procurementInfoLoading"
    />
    <div>
        <div class="container procurements-container">
            <procurement-table-pair></procurement-table-pair>
        </div>
    </div>

    <div class="container procurements-container">
        <div>
            <div class="column" v-if="!procurementInfoLoading">
                <procurements-table
                    title="Bids"
                    :procurement-fiscal-year="procurementInfo.fiscalYear"
                />
            </div>
        </div>
    </div>
    <!-- End Page: pages/browse-procurements -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import UserTools from "@/alivue/components/user-tools";
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import Tooltip from "./tooltip";
    import ProcurementsTable from "./procurements-table";
    import ProcurementPieChartContainer from "./procurement-pie-chart-container";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBinoculars, faFolderOpen, faFolder } from '@fortawesome/free-solid-svg-icons';
    import { faFolderArrowDown } from '@fortawesome/pro-solid-svg-icons';
    import ProcurementTablePair from "./procurement-table-pair.vue";
    import { v4 as uuidv4 } from "uuid";

    export default {
        components: {
            ProcurementTablePair,
            Preloader,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            Tooltip,
            ProcurementsTable,
            ProcurementPieChartContainer,
            FontAwesomeIcon
        },
        data() {
            return {
                faBinoculars: faBinoculars,
                faFolderOpen: faFolderOpen,
                faFolder: faFolder,
                faFolderArrowDown: faFolderArrowDown
            };
        },
        methods: {
        },
        computed: {
        },
        mounted() {
        },
        setup(props) {

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);
            const definitions = computed(() => store.getters.definitions);

            const procurementInfo = ref([]);
            const procurementInfoLoading = ref(true);

            RequestHandler.loadProcurementsInfo().then(data => {
                procurementInfo.value = data;
                procurementInfoLoading.value = false;
            });

            const selectStatus = (status) => {
                let statusId = 'status-' + status;
                let statusInput = document.getElementById(statusId);
                if (!statusInput) {
                    return;
                }

                let filters = document.getElementById('reset-filters');
                filters.click();

                setTimeout(() => {
                    if (!statusInput.checked) {
                        statusInput.click();
                    }

                    let table = document.getElementById('procurementsTableWrapper');
                    if (table) {
                        table.scrollIntoView();
                    }
                }, 250);
            };

            return {
                path: [{title: 'Browse Bids'}],
                showSearch,
                definitions,
                procurementInfo,
                procurementInfoLoading,
                selectStatus,
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .procurements-container .column {
        margin-top: 50px;
        &.is-half-mobile {
            margin-top: 0px;
        }
    }
    .procurements-container--stats {
        margin-bottom: 30px;
    }
    .procurements-container--stats .column {
        cursor: pointer;
        text-align: center;
        padding: 15px;
        > div {
            box-shadow: 0 0.5em 1em -0.125em rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 0%);
            border-radius: 6px;
            padding: 15px;
            background-color: #fff;
            &:hover {
                background-color: #f6f6f6;
            }
        }
    }

    .procurement-count {
        font-size: 30px;
    }
    .procurements-count-title {
        font-size: 20px;
    }

    .procurement-type-icon {
        color: $blue--med;
        font-size: 21px;
    }

    .tac {
        text-align: center;
        width: 100%;
    }

    .procurements-loading {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
</style>
